
import CloudFun, { defineComponent, Operator, ref } from "@cloudfun/core";
import FileUploader from "@/cloudfun/components/FileUploader.vue";

export default defineComponent({
  components: {
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model;
    const multipleFileDropListUploader = ref();
    const singleFileLimitSizeUploader = ref();
    const singleFileDropInputUploader = ref();
    const uploader = ref<any>({});
    return {
      multipleFileDropListUploader,
      singleFileLimitSizeUploader,
      singleFileDropInputUploader,
      uploader
    };
  },
  methods: {
  }
});
