
import CloudFun, {
  defineComponent,
  ref,
  Condition,
  Operator,
} from "@cloudfun/core";
import { watch } from "vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    SelectBox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isModalPopup: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const showModal = ref(false);
    const title = ref("");
    watch(
      () => props.isModalPopup,
      () => {
        console.log("showModal", props.isModalPopup);
        showModal.value = props.isModalPopup;
        title.value = `${props.data.Parent.Name}子類別`;
      }
    );

    const singleWithTreeSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      // dropDownWidth: 600,
      // dropDownHeight: 478,
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Number",
          title: "編號",
          treeNode: true,
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        },
      },
      treeConfig: { children: 'Children' },
    };

    const rules = {
      Name: [
        {
          type: "string",
          required: true,
          message: "請輸入類別名稱",
        },
      ],
      Number: [
        {
          type: "string",
          required: true,
          message: "請輸入類別編號",
        },
      ],
    };

    const reset = () => {
      //   console.log("reset");
      close();
    };

    const submit = () => {
      //   console.log("submit");
      emit("submit", props.data);
    };

    const close = () => {
      showModal.value = false;
      emit("close", showModal);
    };

    return {
      reset,
      submit,
      close,
      rules,
      title,
      showModal,
      singleWithTreeSelectOptions,
    };
  },
});
