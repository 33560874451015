<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產品大分類管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="permission.isCreate"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增分類
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box"></div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input
              type="search"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              clearable="true"
              @keyup="
                (e) => {
                  if (e.$event.keyCode === 13) grid.refresh();
                }
              "
              @clear="grid.refresh()"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
          @currentRowChanged="onGridCurrentRowChanged"
        >
          <template #child="{ row }">
            <div>
              <button
                class="button rounded-full mr-1 bg-theme-8 text-white"
                title="加入"
                :disabled="!permission.isEditing || !permission.isCreate"
                @click="addChild(row)"
              >
                <FontAwesome icon="plus" class="w-4 h-4" />
              </button>
            </div>
          </template>

          <template #modal="{ row, submit, reset }">
            <vxe-form
              :title-width="100"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #form-category="{ data }">
                <select-box
                  class="mr-10 w-full"
                  :transfer="true"
                  :readonly="!permission.isEditing"
                  v-model="data.ParentId"
                  v-bind="singleWithTreeSelectOptions"
                ></select-box>
              </template>
            </vxe-form>
          </template>
          <template #published="{ row }">
            <vxe-switch
              v-model="row.Published"
              open-label="顯示"
              close-label="隱藏"
              :disabled="!permission.isEditing"
              @change="switchChange(row, $event)"
            ></vxe-switch>
          </template>
          <!-- <template #category="{ row }">
            <vxe-switch
              v-model="row.Published"
              open-label="顯示"
              close-label="隱藏"
              @change="switchChange(row, $event)"
            ></vxe-switch>
          </template> -->
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <child-modal
      :isModalPopup="showChildModal"
      :data="masterRow"
      @close="childModalClose"
      @submit="childSubmit"
    ></child-modal>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, {
  defineComponent,
  reactive,
  ref,
  Condition,
  Operator,
} from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import ChildModal from "./Child/Modal.vue";
import model from "@/models";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    Grid,
    ChildModal,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const masterRow = reactive({});
    const grid = ref<any>({});
    const permission = reactive({
      isCreate: model?.user.Permissions.includes("BaseDataCreate"),
      isEditing: model?.user.Permissions.includes("BaseDataUpdate"),
      isDelete: model?.user.Permissions.includes("BaseDataDelete"),
    });
    const detailTitle = ref({});
    const showChildModal = ref(false);
    const gridOptions = {
      title: "產品類別",
      canCreateRow: permission.isCreate,
      canUpdateRow: permission.isEditing,
      canDeleteRow: permission.isDelete,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },

      columns: [
        {
          field: "Number",
          title: "類別編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          width: "120",
          fixed: "left",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "類別名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "Auto",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Ordinal",
          title: "顯示排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Published",
          title: "顯示",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          slots: { default: "published" },
        },
        {
          field: "Id",
          title: "子類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "80",
          slots: { default: "child" },
        },
      ],
      promises: {
        query: model
          ? (params: any) => {
              if (params.condition) {
                params.condition.and("ParentId", Operator.IsNull);
              }
              params.type = 1;
              return model.dispatch("category/query", params);
            }
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("category/query", {
                keyword: grid.value.keyword,
              })
          : undefined,

        save: model
          ? (params: any) => {
              if (permission.isEditing || permission.isCreate)
                return model.dispatch("category/save", params);
              else return Promise.resolve();
            }
          : undefined,
      },
      modalConfig: { height: "370", width: "600" },
      treeConfig: { children: "Children" },
    };

    const singleWithTreeSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      // dropDownWidth: 600,
      // dropDownHeight: 478,
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Number",
          title: "編號",
          treeNode: true,
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        },
      },
      treeConfig: { children: "Children" },
    };

    const formOptions = {
      items: [
        {
          field: "Number",
          title: "類別編號",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Name",
          title: "類別名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Parent.Name",
          title: "父類別",
          span: 24,
          slots: { default: "form-category" },
        },
        {
          field: "Ordinal",
          title: "顯示排序",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              type: "integer",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Published",
          title: "顯示",
          span: 24,
          itemRender: {
            name: "$switch",
            props: {
              openLabel: "顯示",
              closeLabel: "關閉",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入類別名稱",
          },
        ],
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入類別編號",
          },
        ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const initData = (row: any) => {
      row.Id = 0;
      row.Name = "";
      row.Number = "";
      row.Ordinal = 0;
      row.Published = false;
      row.Type = 1;
    };

    // onMounted(() => {
    //   console.log("");
    // });

    const switchChange = async (row: any, $event: any) => {
      const payload = await model?.dispatch("category/find", row.Id);
      if (payload) {
        payload.Published = $event.value;
        await model?.dispatch("category/update", payload);
      }
    };

    return {
      initData,
      switchChange,
      masterRow,
      detailTitle,
      grid,
      gridOptions,
      formOptions,
      showChildModal,
      singleWithTreeSelectOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      permission,
    };
  },
  methods: {
    onGridModalResize(size: any) {
      // this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("category/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    onGridCurrentRowChanged(row: any) {
      //   this.masterRow = row;
      // this.detailTitle = `${this.masterRow.Number} / ${this.masterRow.Name}`;
    },
    addChild(row: any) {
      console.log(row);
      Object.assign(this.masterRow, {
        Name: "",
        Number: "",
        Parent: { id: row.Id, Name: row.Name },
        ParentId: row.Id,
        Ordinal: 0,
        Published: true,
        Type: 1,
      });
      this.showChildModal = true;
    },
    childModalClose(el: any) {
      console.log("close", el);
      this.showChildModal = false;
    },
    childSubmit(data: any) {
      console.log("data", data);
      debugger;
      model.dispatch("category/insert", data).then(
        (payload) => {
          if (payload) {
            this.grid.reload();
            this.showChildModal = false;
          }
        },
        (reason) => {
          CloudFun.send("error", { subject: "刪除失敗", content: reason });
        }
      );
    },
  },
});
</script>

<style scoped></style>
