import { IModel } from "@cloudfun/core";
import CustomeStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data/load_options";
import $cf from "../assets/scripts/cloudfun";
import { cloneDeep } from "../assets/scripts/cloudfun/clone";
/* eslint-disable */
const stringifyDatesInFilters = (conditions: any) => {
  conditions.forEach((value: any, index: any) => {
    if (Array.isArray(value)) {
      stringifyDatesInFilters(value);
    } else if ($cf.inspectExt.isDate(value)) {
      conditions[index] = $cf.dateExt.formatDate(value);
    }
  });
};

const formatSortingQuery = (conditions: any) => {
  if (!Array.isArray(conditions)) conditions = [conditions];
  return conditions.map((value: any) => {
    const result: any = {
      selector:
        $cf.inspectExt.isFunction(value) || "string" === typeof value
          ? value
          : value.getter || value.field || value.selector, // eslint-disable-line
      desc: !!(value.desc || String(value.dir).charAt(0).toLowerCase() === "d"),
    };
    if (value.compare) {
      result.compare = value.compare;
    }
    return result;
  });
};

const isAdvancedGrouping = (conditions: any) => {
  if (!$cf.inspectExt.isArray(conditions)) return false;
  return conditions.some((e: any) => "groupInterval" in e || "isExpanded" in e);
};

const convertLoadOptionsToParams = (options: any, isCountQuery = false) => {
  let params: Record<string, any> = {};
  if (isCountQuery) params.isCountQuery = isCountQuery;
  ["skip", "take", "requireTotalCount", "requireGroupCount"].forEach(function (
    key
  ) {
    if (key in options) params[key] = (options as any)[key];
  });

  let filter = options.filter; // accept nested array
  let group = options.group;
  let select = options.select;
  // console.log(
  //   "search: ",
  //   options.searchExpr,
  //   options.searchOperation,
  //   options.searchValue
  // );
  if (!filter && options.searchExpr)
    filter = [options.searchExpr, options.searchOperation, options.searchValue];
  if (Array.isArray(filter)) {
    // filter = extend(true, [], filter);
    stringifyDatesInFilters(filter);
    params.filter = JSON.stringify(filter);
  }

  if (options.sort)
    params.sort = JSON.stringify(formatSortingQuery(options.sort));

  if (group) {
    if (!isAdvancedGrouping(group)) group = formatSortingQuery(group);
    params.group = JSON.stringify(group);
  }

  if (options.totalSummary)
    params.totalSummary = JSON.stringify(options.totalSummary);

  if (options.groupSummary)
    params.groupSummary = JSON.stringify(options.groupSummary);

  if (select) {
    if (!Array.isArray(select)) select = [select];
    params.select = JSON.stringify(select);
  }

  console.log("options.loadParams", options.loadParams);
  if (options.loadParams) params = cloneDeep({}, params, options.loadParams);

  return params;
};

const getKeyFilter = (key: string | string[], value: any) => {
  if (!Array.isArray(key)) return [key, value];
  return key.map((i) => [i, value[i]]);
};

function createDataSource(
  model: IModel,
  key: string | string[],
  loadAction: string,
  insertAction?: string,
  updateAction?: string,
  deleteAction?: string,
  onLoaded?: Function
): CustomeStore {
  return new CustomeStore({
    key,
    load: async (options: LoadOptions) => {
      console.log(
        "loadOptions: ",
        options,
        convertLoadOptionsToParams(options)
      );
      const response = await model.dispatch(
        loadAction,
        convertLoadOptionsToParams(options)
      );
      console.log("dataSource", response);
      return { data: response.data };
    },
    totalCount: async (options: LoadOptions) => {
      const response = await model.dispatch(
        loadAction,
        convertLoadOptionsToParams(options, true)
      );
      return response.totalCount;
    },
    byKey: async (value: any) => {
      const response = await model.dispatch(
        loadAction,
        convertLoadOptionsToParams({ filter: getKeyFilter(key, value) })
      );
      return response.data[0];
    },
    insert: async (values: any) => {
      const response = await model.dispatch(insertAction || loadAction, {
        values: JSON.stringify(values),
      });
      return response.data[0];
    },
    update: async (key: any, values: any) => {
      const response = await model.dispatch(updateAction || loadAction, {
        key,
        values: JSON.stringify(values),
      });
      return response;
    },
    remove: async (key: any) => {
      await model.dispatch(deleteAction || loadAction, { key });
    },
    onLoaded: (result: any) => {
      if (onLoaded) onLoaded(result);
    },
  });
}

export default createDataSource;
