<template>
  <div>
    <template v-if="$user.Id">
      <div
        class="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6"
        style="position: relative"
      >
        <loading
          v-model:active="load.isLoading"
          :is-full-page="load.fullPage"
        />
        <!-- BEGIN: 基本資料 -->
        <div class="col-span-12 mt-8">
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FontAwesome
                      icon="user"
                      class="report-box__icon text-theme-10 mr-3"
                    />
                    <div class="text-lg font-medium truncate mr-3">
                      訪客人數
                    </div>
                    <div class="text-lg font-medium truncate ml-auto">
                      {{ gaData.Users }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FontAwesome
                      icon="link"
                      class="report-box__icon text-theme-10 mr-3"
                    />
                    <div class="text-lg font-medium truncate mr-3">
                      累計連接
                    </div>
                    <div class="text-lg font-medium truncate ml-auto">
                      {{ gaData.Sessions }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FontAwesome
                      icon="eye"
                      class="report-box__icon text-theme-10 mr-3"
                    />
                    <div class="text-lg font-medium truncate mr-3">瀏覽量</div>
                    <div class="text-lg font-medium truncate ml-auto">
                      {{ gaData.Pageviews }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FontAwesome
                      icon="file"
                      class="report-box__icon text-theme-10 mr-3"
                    />
                    <div class="text-lg font-medium truncate mr-3">
                      單次連結瀏覽頁數
                    </div>
                    <div class="text-lg font-medium truncate ml-auto">
                      {{ gaData.PageviewsPerSession?.toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FontAwesome
                      icon="clock"
                      class="report-box__icon text-theme-10 mr-3"
                    />
                    <div class="text-lg font-medium truncate mr-3">
                      平均停留時間
                    </div>
                    <div class="text-lg font-medium truncate ml-auto">
                      {{ gaData.AvgSessionDuration?.toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FontAwesome
                      icon="external-link-alt"
                      class="report-box__icon text-theme-10 mr-3"
                    />
                    <div class="text-lg font-medium truncate mr-3">跳出率</div>
                    <div class="text-lg font-medium truncate ml-auto">
                      {{ gaData.BounceRate?.toFixed(1) }} %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: 基本資料 -->
        <!-- BEGIN: pie -->
        <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5 font-bold">訪客率</h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <div id="pie-chart" class="p-5">
              <div class="preview">
                <PieChart
                  :width="pieChart.width"
                  :height="pieChart.height"
                  :data="pieChart.data"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- END: pie -->
        <!-- BEGIN: line -->
        <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5 font-bold">
              訪客人數
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <div id="line-chart" class="p-5">
              <div class="preview">
                <LineChart
                  :width="lineChart.width"
                  :height="lineChart.height"
                  :data="lineChart.data"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- END: line -->
        <!-- BEGIN: 訪客國家 -->
        <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5 font-bold">
              訪客國家
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">國家</th>
                  <th class="text-center whitespace-nowrap">訪問數</th>
                  <th class="text-center whitespace-nowrap">比例</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in gaData.UsersByCountry"
                  :key="index"
                  class="intro-x"
                >
                  <td>
                    <div class="font-medium whitespace-nowrap">
                      {{ item.Name }}
                    </div>
                  </td>
                  <td class="text-center">{{ item.Value }}</td>
                  <td class="text-center">
                    {{
                      item.Value && gaData.TotalCountryUsers
                        ? (
                            (item.Value / gaData.TotalCountryUsers) *
                            100
                          ).toFixed(2)
                        : null
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END: 訪客國家 -->
        <!-- BEGIN: 訪客語系 -->
        <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5 font-bold">
              訪客語系
            </h2>
          </div>
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">語系</th>
                  <th class="text-center whitespace-nowrap">訪問數</th>
                  <th class="text-center whitespace-nowrap">比例</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in gaData.UsersByLanguage"
                  :key="index"
                  class="intro-x"
                >
                  <td>
                    <div class="font-medium whitespace-nowrap">
                      {{ item.Name }}
                    </div>
                  </td>
                  <td class="text-center">{{ item.Value }}</td>
                  <td class="text-center">
                    {{
                      item.Value && gaData.TotalLanguageUsers
                        ? (
                            (item.Value / gaData.TotalLanguageUsers) *
                            100
                          ).toFixed(2)
                        : null
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END: 訪客語系 -->
      </div>
    </template>
    <template v-else>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <div class="font-medium">
              當前使用者：{{ $user ? $user.Name : "" }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, ref, provide, onMounted, reactive } from "vue";
import CloudFun, { Model } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import LineChart from "@/components/line-chart/Main.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "@/router";

export default defineComponent({
  components: {
    PieChart,
    LineChart,
    Loading,
  },
  setup() {
    if (!CloudFun.user.Id) router.push("/login");
    const salesReportFilter = ref("");
    var load = reactive({
      isLoading: false,
      fullPage: false,
    });
    var gaData = reactive<any>({});

    var pieChart = reactive({
      width: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 0,
      },
      data: {
        labels: ["新訪客率", "舊訪客率"],
        datasets: [
          {
            data: [50, 50],
            backgroundColor: ["#26a1ff", "#ff2626"],
            hoverBackgroundColor: ["#26a1ff", "#ff2626"],
            borderWidth: 5,
            borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff",
          },
        ],
      },
    });

    var lineChart = reactive({
      width: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 0,
      },
      data: {
        labels: [],
        datasets: [
          {
            label: "人數",
            data: [],
            borderWidth: 2,
            borderColor: "#3160D8",
            backgroundColor: "transparent",
            pointBorderColor: "transparent",
          },
        ],
      },
    });

    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;
    const getGoogleAnalytics = () => {
      return new Promise((resolve, reject) => {
        Model.createHttpClient(baseUrl)
          .get(`${baseUrl}/api/System/GoogleAnalytics`, {})
          .then(function (response: any) {
            resolve(response.payload);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    };

    onMounted(() => {
      if (CloudFun.user.Id) {
        load.isLoading = true;
        getGoogleAnalytics()
          .then((data: any) => {
            Object.assign(gaData, data);
            gaData.TotalCountryUsers = data.UsersByCountry.map(
              (e: any) => e.Value
            ).reduce((a: number, b: number) => a + b);
            gaData.TotalLanguageUsers = data.UsersByLanguage.map(
              (e: any) => e.Value
            ).reduce((a: number, b: number) => a + b);
            pieChart.data.labels = ["新訪客率", "舊訪客率"];
            pieChart.data.datasets[0].data = [
              data.PercentNewSessions,
              100 - data.PercentNewSessions,
            ];
            lineChart.data.labels = data.UsersByDate.map((e: any) => {
              return e.Name;
            });
            lineChart.data.datasets[0].data = data.UsersByDate.map((e: any) => {
              return e.Value;
            });
          })
          .catch((error) =>
            CloudFun.send("error", {
              subject: "GA資料取得失敗",
              content: error.message,
            })
          )
          .finally(() => {
            load.isLoading = false;
          });
      }
    });

    return {
      gaData,
      pieChart,
      lineChart,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      load,
    };
  },
});
</script>
