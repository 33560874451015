<template>
  <!-- BEGIN: HTML Table Data -->
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 lg:col-span-12 xxl:col-span-12">
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">銷售條件</h2>
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-0">
          <a
            href="javascript:void()"
            class="flex items-center px-3 py-2 mt-2 rounded-md"
            v-if="discounts && discounts.length === 0"
            @click="newDiscount"
          >
            <PlusIcon class="w-4 h-4 mr-2" /> 新增條件
          </a>
          <div class="border-t border-gray-200 dark:border-dark-5 mt-5 pt-5">
            <div
              class="flex items-center px-3 py-2 rounded-md"
              v-for="(item, index) in discounts"
              :key="index"
            >
              <div class="w-full flex">
                <div>
                  {{ item.DiscountMixName }}
                </div>
                <div
                  class="pl-3"
                  v-show="item.Type === 0 || item.Type === 1 || item.Type === 2"
                >
                  <dx-text-box
                    :read-only="true"
                    :value="item.DiscountItems[0].Qty"
                  ></dx-text-box>
                </div>

                <div
                  class="pl-3"
                  v-show="
                    item.Type === 10 || item.Type === 12 || item.Type === 13
                  "
                >
                  <dx-data-grid
                    ref="grid"
                    :show-borders="true"
                    :data-source="item.DiscountItems"
                    :columns="gridCoumns"
                    row-alternation-enabled="true"
                  >
                  </dx-data-grid>
                </div>
              </div>
              <div class="flex justify-end w-24">
                <!-- <button
                  class="button rounded-full mr-1 bg-blue-600 text-white"
                  title="編輯"
                  v-show="permission.isCreate || permission.isEditing"
                  @click.stop="editColor(item)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button> -->

                <button
                  class="button rounded-full bg-red-600 text-white"
                  title="刪除"
                  @click.stop="removeDiscount(item.DiscountMixId)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: File Manager Menu -->
      </div>
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: File Manager Filter -->

        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div class="w-full">
          <!-- <grid
            ref="grid"
            v-bind="gridOptions"
            @addNewRow="
              (row, callback) => {
                initData(row);
                callback();
              }
            "
            @edit="onGridEdit"
            @modalResize="onGridModalResize"
            @removeSelectedRows="onGridRemoveSelectedRows"
          >
          
            <template #modal="{ row, submit, reset }">
              <vxe-form
                :title-width="100"
                title-align="right"
                v-bind="formOptions"
                :data="row"
                @reset="reset"
                @submit="submit"
              >
              </vxe-form>
            </template>
            <template #published="{ row }">
              <vxe-switch
                v-model="row.Published"
                open-label="有"
                close-label="無"
                :disabled="!permission.isEditing"
              ></vxe-switch>
            </template>
          </grid> -->
        </div>
        <!-- END: Directory & Files -->
      </div>
    </div>

    <discount-edit
      v-model:data="editItem"
      v-model:show="discountModalVisible"
      @update:show="() => (discountModalVisible = $event)"
      @save="onDiscountSave"
    ></discount-edit>
  </div>
  <!-- END: HTML Table Data -->
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}
.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script>
import { computed, onMounted, reactive, watch, nextTick } from "vue";
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import DiscountEdit from "./modal/Discount";
import { DxDataGrid } from "devextreme-vue/data-grid";

import { DxTextBox } from "devextreme-vue";

export default defineComponent({
  components: {
    // Grid,
    DiscountEdit,
    DxTextBox,
    DxDataGrid,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const discountModalVisible = ref(false);
    const editItem = reactive({});
    const product = ref({});
    const discounts = ref([]);
    const permission = reactive({
      isCreate: model?.user.Permissions.includes("ProductCreate"),
      isEditing: model?.user.Permissions.includes("ProductUpdate"),
      isDelete: model?.user.Permissions.includes("ProductUpdate"),
    });

    const canEdit = permission.isCreate || permission.isEditing;

    const gridCoumns = [
      {
        dataField: "Qty",
        caption: "級距數量",
        sortOrder: "asc",
      },
      {
        dataField: "Discount",
        caption: "折扣值",
      },
      {
        dataField: "Month",
        caption: "適用月份",
      },
    ];

    watch(
      () => props.data,
      () => {
        // getColors(props.data.Id);
        getDiscounts(props.data.Id);
        product.value = props.data;
        console.log("props.product", product.value);
      }
    );

    const getDiscounts = (key) => {
      discounts.value.length = 0;

      model?.dispatch("product/queryDiscount", key).then(
        (payload) => {
          console.log("getDiscounts", payload);
          // discounts.value.push(...payload);
          if (payload == null) payload = [];
          discounts.value = payload;
          // Object.assign(discounts.value, payload);

          //   if (productColors.length === 0) {
          //     Object.assign(currentColor, { Id: 0 });
          //   } else {
          //     Object.assign(currentColor, productColors[0]);
          //   }

          //   grid.value.reload();
        },
        (failure) =>
          CloudFun.send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    };

    const removeDiscount = (key) => {
      console.log("item", key);

      if (!confirm("確定刪除銷售條件！？")) return;

      model?.dispatch("product/deleteDiscount", key).then(
        (payload) => {
          getDiscounts(product.value.Id);
          CloudFun.send("info", {
            subject: "刪除成功",
            content: "刪除銷售條件完成",
          });
        },
        (failure) =>
          CloudFun.send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    };

    return {
      discountModalVisible,
      editItem,
      product,
      grid,
      canEdit,
      discounts,
      gridCoumns,
      getDiscounts,
      removeDiscount,
    };
  },
  methods: {
    newDiscount() {
      this.editItem = {
        ProductId: this.product.Id,
        ActivityId: 0,
        DiscountValue: null,
        DiscountItems: [
          {
            Qty: 0,
            Discount: 0,
            Month: "",
          },
        ],
      };
      this.discountModalVisible = true;
    },

    onDiscountSave(params, callback) {
      console.log("params", params);

      //   const payload = await this.$model.dispatch(
      //     "product/discount/create",
      //     params
      //   );

      //   if (payload) {
      //     CloudFun.send("info", {
      //       subject: "新增成功",
      //       content: "新增銷售條件完成",
      //     });
      //   }

      this.$model
        .dispatch("product/createDiscount", params)
        .then(
          (resp) => {
            this.getDiscounts(this.product.Id);
            CloudFun.send("info", {
              subject: "新增成功",
              content: "新增銷售條件完成",
            });
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", {
              subject: "操作失敗！",
              content: failure,
            });
          }
        )
        .finally();

      callback();
    },
  },
});
</script>
