<template>
  <div>
    <dx-data-grid
      ref="detailGrid"
      key-expr="Id"
      :show-borders="true"
      :data-source="dataSource"
      column-width="auto"
      :columns="columns"
      show-column-lines="true"
      show-row-lines="true"
      width="100%"
      focusedEnabled="true"
      row-alternation-enabled="true"
      @initialized="onGridInitialized"
    >
      <dx-selection mode="single"></dx-selection>
      <dx-header-filter :visible="true" />
      <dx-search-panel :visible="true"></dx-search-panel>
      <dx-paging :page-size="pageSettings.pageSize" />
      <dx-group-panel :visible="true" />
      <dx-pager
        v-if="pageSettings.showPager"
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSettings.pagerSizes"
        :show-info="true"
      />
      <dx-summary>
        <template v-for="(item, i) in totalItems" :key="`summary_${i}`">
          <dx-total-item
            :column="item.column"
            :summary-type="item.summaryType"
            :skip-empty-values="item.skipEmptyValues"
            :value-format="item.valueFormat"
            :display-format="item.displayFormat"
            :align-by-column="item.alignByColumn"
          ></dx-total-item>
        </template>
      </dx-summary>
      <dx-export
        :enabled="exportSettings.enabled"
        :allow-export-selected-data="exportSettings.allowExportSelectedData"
        :file-name="exportSettings.fileName"
        :texts="exportSettings.texts"
        :customize-excel-cell="exportSettings.customizeExcelCell"
      />
    </dx-data-grid>
  </div>
</template>

<script>
import CloudFun, { defineComponent, reactive, ref } from "@cloudfun/core";

import {
  DxDataGrid,
  DxColumnFixing,
  DxSummary,
  DxTotalItem,
  DxPaging,
  DxPager,
  DxHeaderFilter,
  DxSearchPanel,
  DxGroupPanel,
  DxExport,
  DxSelection,
} from "devextreme-vue/data-grid";
export default defineComponent({
  components: {
    DxDataGrid,
    DxSummary,
    DxTotalItem,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxSearchPanel,
    DxGroupPanel,
    DxExport,
    DxSelection,
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  setup(props) {
    const detailGrid = ref({});
    const dataSource = ref({});
    const columns = ref([]);
    const totalItems = ref([]);
    const model = CloudFun.current?.model;
    const permission = reactive({
      isExport: model?.user.Permissions.includes("OrderExport"),
    });
    const pageSettings = reactive({
      showPager: true,
      pageSize: 10,
      pagerSizes: [10, 25, 50],
    });
    const exportSettings = reactive({
      enabled: permission.isExport,
      allowExportSelectedData: false,
      texts: { exportAll: "匯出 Excel" },
    });

    function reload(id, storeName) {
      if (id > 0) {
        exportSettings.fileName = `${storeName}_訂單明細`;
        columns.value = [];
        totalItems.value = [];
        model?.dispatch("orderItem/queryDetail", id).then(
          (payload) => {
            console.log("payload", payload);
            console.log("detailGrid", detailGrid);
            if (payload.data && payload.data.length > 0) {
              const propertyNames = Object.getOwnPropertyNames(payload.data[0]);
              columns.value = [
                {
                  dataField: "ProductNumber",
                  caption: "產品編號",
                  allowSearch: true,
                },
                {
                  dataField: "ProductName",
                  caption: "產品名稱",
                  allowSearch: true,
                },
                {
                  dataField: "ColorName",
                  caption: "顏色",
                },
                {
                  dataField: "SizeName",
                  caption: "尺寸",
                },
                {
                  dataField: "GoodsNumber",
                  caption: "貨號",
                },
                {
                  dataField: "JANCode",
                  caption: "JAN Code",
                },
                {
                  dataField: "EndDate",
                  caption: "下單截止日",
                },
                {
                  dataField: "PurchasePrice",
                  caption: "採購單價",
                  format: { type: "currency", precision: 0 },
                },
                {
                  dataField: "Amount",
                  caption: "採購金額",
                  format: { type: "currency", precision: 0 },
                },
              ];

              totalItems.value.push({
                type: "total",
                column: "Amount",
                summaryType: "sum",
                displayFormat: "總計: {0}",
                valueFormat: { type: "currency", precision: 0 },
              });

              console.log("propertyNames", propertyNames);
              if (propertyNames) {
                for (var i = 14; i < propertyNames.length; i++) {
                  columns.value.push({
                    dataField: propertyNames[i],
                    allowSearch: false,
                    caption:
                      propertyNames[i] === "Subtotal"
                        ? "小計"
                        : propertyNames[i],
                  });

                  totalItems.value.push({
                    type: "total",
                    column: propertyNames[i],
                    summaryType: "sum",
                    displayFormat: "數量: {0}",
                    valueFormat: { type: "fixedPoint", precision: 0 },
                  });

                  console.log("columns.push", propertyNames[i]);
                }
              }
            }

            this.dataSource = payload.data;
          },
          (failure) => {
            debugger;
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        );
      }
    }

    return {
      detailGrid,
      reload,
      dataSource,
      columns,
      totalItems,
      pageSettings,
      exportSettings,
      permission,
    };
  },
});
</script>

<style scoped></style>
