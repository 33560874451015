<template>
  <div>
    <dx-popup
      ref="modal"
      :visible="show"
      :title="title"
      width="50%"
      height="Auto"
      @hidden="close"
    >
      <div class="h-full">
        <div class="w-full flex">
          <!-- <div class="w-52">
            <div class="w-48 h-48 border">
              <img
                src="https://via.placeholder.com/512x512.png"
                v-if="image?.Uri === null"
              />
              <img
                :src="image?.Uri"
                v-if="image?.Uri && image?.Uri.length > 0"
              />
            </div>
          </div> -->
          <div class="w-full mx-5">
            <form @submit.prevent="onSubmit">
              <dx-form :form-data="formData">
                <dx-group-item :col-count="1">
                  <dx-simple-item
                    data-field="Product.Number"
                    editor-type="dxTextBox"
                    :label="{ text: '產品編號', alignment: 'right' }"
                    :editor-options="{ readOnly: true }"
                  >
                  </dx-simple-item>
                  <dx-simple-item
                    data-field="Product.Name"
                    editor-type="dxTextBox"
                    :label="{ text: '產品名稱', alignment: 'right' }"
                    :editor-options="{ readOnly: true }"
                  >
                  </dx-simple-item>

                  <dx-simple-item
                    col-span="2"
                    data-field="ColorId"
                    editor-type="dxSelectBox"
                    :label="{ text: '顏色', alignment: 'right' }"
                    :editor-options="{
                      readOnly: !canEdit,
                      searchEnabled: true,
                      searchMode: 'contains',
                      searchExpr: ['Number', 'Name'],
                      displayExpr: (item) => `${item.Number} ${item.Name} `,
                      valueExpr: 'Id',
                      showDataBeforeSearch: true,
                      showClearButton: true,
                      dataSource: colorDataSource,
                    }"
                  >
                    <DxRequiredRule></DxRequiredRule
                  ></dx-simple-item>
                  <dx-simple-item
                    data-field="Published"
                    editor-type="dxSwitch"
                    :label="{ text: '顯示', alignment: 'right' }"
                    :editor-options="{ readOnly: false }"
                  >
                  </dx-simple-item>
                  <dx-simple-item col-span="2">
                    <template #default>
                      <div class="grid justify-items-end">
                        <dx-button
                          :visible="canEdit"
                          icon="check"
                          text="儲存"
                          type="default"
                          validation-group="form"
                          use-submit-behavior="true"
                        ></dx-button>
                      </div>
                    </template>
                  </dx-simple-item>
                </dx-group-item>
              </dx-form>
            </form>
          </div>
        </div>
      </div>
      <!-- <dx-simple-item
                data-field="ColorId"
                :label="{ text: '顏色', alignment: 'right' }"
                col-span="2"
              >
                <template #default>
                  <select-box
                    class="mr-10"
                    v-bind="singleWithSearchSelectOptions"
                    v-model="formData.ColorId"
                  />
                </template>
              </dx-simple-item> -->

      <!-- <div calss="text-center">
        <file-uploader
          name="files"
          ref="uploader"
          extensions="png,gif,jpg,jpeg"
          accept="image/png,image/gif,image/jpeg"
          v-model="files"
          :action="postAction"
          :multiple="false"
          @filter="inputFilter"
        >
        </file-uploader>
      </div>
      <div class="mt-3">
        <vxe-button
          status="success"
          content="上傳"
          round
          @click="save(editImage)"
        ></vxe-button>
      </div> -->
    </dx-popup>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, { defineComponent, ref, computed } from "@cloudfun/core";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
import { DxPopup } from "devextreme-vue";
import { DxDataConfigurations } from "../../../../services/data-source-service";
import {
  DxSelectBox,
  DxNumberBox,
  DxTextArea,
  DxTextBox,
  DxDropDownBox,
  DxTreeView,
  DxTagBox,
  DxSwitch,
  DxButton,
  DxLoadPanel,
} from "devextreme-vue";

import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";

export default defineComponent({
  components: {
    FileUploader,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxNumberBox,
    DxTextArea,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxTextBox,
    DxDropDownBox,
    DxTreeView,
    DxTagBox,
    DxLabel,
    DxSwitch,
    DxButton,
    DxRequiredRule,
    DxLoadPanel,
    SelectBox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const uploader = ref({});
    const files = ref([]);
    const formData = ref({});
    const modal = ref({});
    const editImage = ref({});
    const image = ref({});
    const postAction = computed(() => {
      return `${process.env.VUE_APP_BACKEND_URL}/api/files`;
    });
    const canEdit = ref(true);
    const colorDataSource = DxDataConfigurations.ColorDataConfiguration({
      selectExpr: ["Id", "Name", "Number"],
    });
    const sizeDataSource = DxDataConfigurations.SizeDataConfiguration();
    const title = computed(
      () => `${props.data.Id && props.data.Id > 0 ? "修改" : "新增"}顏色/尺寸`
    );

    const singleWithSearchSelectOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇顏色",
      dropDownWidth: 600,
      dropDownHeight: 478,
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "顏色",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
        },
      ],
      promises: {
        find: (value) => model.dispatch("color/find", value), // eslint-disable-line
        query: (params) => model.dispatch("color/query", params), // eslint-disable-line
      },
    };
    // const formData = computed(() => props.data);
    return {
      modal,
      image,
      uploader,
      formData,
      files,
      title,
      editImage,
      postAction,
      canEdit,
      colorDataSource,
      sizeDataSource,
    };
  },

  watch: {
    data(newValue, oldValue) {
      this.formData = newValue;
      console.log("data formdata", this.formData);
      if (this.formData.PictureId && this.formData.PictureId > 0) {
        this.$model.dispatch("picture/find", this.formData.PictureId).then(
          (resp) => {
            console.log("picture", resp);
            this.image = resp;
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else {
        this.image = { Uri: "https://via.placeholder.com/512x512.png" };
      }
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (!newFile) return prevent();
      if (newFile && !oldFile) {
        if (!/\.(png|gif|jpg|jpeg)$/i.test(newFile.name)) {
          alert("未支援此種圖片格式");
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          console.log("nextTick");
        });
      }
      if (!newFile && oldFile) {
      }
    },
    deletePicture() {
      if (this.editImage.Id === 0) {
        // CloudFun.send('error', { subject: '操作失敗！', content: '請確認圖片是否存在！' })
        if (this.files.length > 0) this.removeFile();
        return;
      }
      this.$model
        .dispatch(`picture/deletePicture`, this.editImage.Id)
        .then(
          (paylod) => {
            CloudFun.send("info", {
              subject: "刪除成功！",
              content: "圖片刪除成功",
            });
            this.removeFile();
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally();
    },
    removeFile() {
      console.log(this.files.length > 0);
      if (this.files.length > 0) this.files = [];
      this.editImage.Uri = "";
    },
    close() {
      this.files = [];
      this.$emit("update:show", false);
    },
    async save(image) {
      const params = {
        id: this.current.Id,
        types: [],
        updateRows: [],
      };
      if (this.uploader.files.length) {
        this.uploader.files.forEach((e) => {
          e.active = true;
        });
        await this.uploader.upload().then((files) => {
          files.forEach((file) => {
            image.Uri = file.response.payload?.length
              ? file.response.payload[0]
              : undefined;
            image.ContentType = file.type;
            params.updateRows.push({ ...image });
          });
        });
      } else if (image.Id) params.updateRows.push({ ...image });
      else {
        alert("請選擇上傳的照片");
        return;
      }
      const action = () => {
        this.files = [];
        this.editImage = {};
        this.$emit("update:show", false);
      };
      if (this.$attrs.onSave) this.$emit("save", params, action);
      else action();
    },
    onSubmit(e) {
      console.log("formData", this.formData);
      const params = {
        insertRows: [],
        updateRows: [],
      };

      if (this.formData.Id === 0) params.insertRows = [this.formData];
      else params.updateRows = [this.formData];

      const action = () => {
        this.formData = {};
        this.$emit("update:show", false);
      };

      if (this.$attrs.onSave) this.$emit("save", params, action);
      else action();
      //   formData.value.Categories = selectedCategories.value.map((el) => {
      //     return { CategoryId: el, ProductId: formData.value.Id };
      //   });

      //   formData.value.Tags = tags.value.map((el) => {
      //     return { TagId: el, ProductId: formData.value.Id };
      //   });
      //   console.log("formData", formData.value);
      //   console.log("tags", tags.value);
      //   loading.visible = true;

      //   params.updateRows = [formData.value];
      //   model
      //     .dispatch("product/save", params)
      //     .then(
      //       (resp) => {
      //         console.log("update success", resp);
      //         emit("refresh");
      //         CloudFun.send("info", {
      //           subject: formData.value.Id == 0 ? "新增成功" : "更新成功",
      //           content:
      //             formData.value.Id == 0
      //               ? "產品資料新增完成"
      //               : "產品資料更新完成",
      //         });
      //       },
      //       (failure) => {
      //         console.log("update error", error);
      //         CloudFun.send("error", { subject: "操作失敗！", content: failure });
      //       }
      //     )
      //     .finally(() => (loading.visible = false));

      e.preventDefault();
    },
  },
});
</script>

<style scoped></style>
