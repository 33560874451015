<template>
  <div>
    <dx-popup
      ref="modal"
      :visible="show"
      :title="title"
      width="50%"
      height="Auto"
      @hidden="close"
    >
      <div class="h-full">
        <div class="w-full flex">
          <div class="w-full mx-5">
            <form @submit.prevent="onSubmit">
              <dx-form :form-data="formData">
                <dx-group-item :col-count="1">
                  <dx-simple-item
                    data-field="ActivityId"
                    editor-type="dxSelectBox"
                    :label="{ text: '銷售條件', alignment: 'right' }"
                    :editor-options="{
                      readOnly: false,
                      displayExpr: 'Name',
                      valueExpr: 'Id',
                      showDataBeforeSearch: true,
                      dataSource: activityDataSource,
                    }"
                  >
                  </dx-simple-item>

                  <dx-simple-item
                    data-field="DiscountValue"
                    editor-type="dxTextBox"
                    :visible="formData.ActivityId < 4"
                    :label="{ text: '設定值', alignment: 'right' }"
                    :editor-options="{ readOnly: false }"
                  >
                  </dx-simple-item>

                  <dx-simple-item
                    data-field="DiscountItems"
                    :label="{ text: '設定值', alignment: 'right' }"
                    :visible="
                      formData.ActivityId == 4 ||
                      formData.ActivityId == 6 ||
                      formData.ActivityId == 8 ||
                      formData.ActivityId == 9
                    "
                  >
                    <template #default>
                      <dx-data-grid
                        ref="grid"
                        :show-borders="true"
                        :data-source="formData.DiscountItems"
                        width="100%"
                        :columns="gridCoumns"
                        row-alternation-enabled="true"
                      >
                        <dx-editing
                          :allow-adding="true"
                          :allow-updating="true"
                          :allow-deleting="true"
                          :select-text-on-edit-start="true"
                          :start-edit-action="click"
                          mode="batch"
                        >
                        </dx-editing>
                      </dx-data-grid>
                    </template>
                  </dx-simple-item>

                  <dx-simple-item col-span="2">
                    <template #default>
                      <div class="grid justify-items-end">
                        <dx-button
                          :visible="canEdit"
                          icon="check"
                          text="儲存"
                          type="default"
                          validation-group="form"
                          use-submit-behavior="true"
                        ></dx-button>
                      </div>
                    </template>
                  </dx-simple-item>
                </dx-group-item>
              </dx-form>
            </form>
          </div>
        </div>
      </div>
    </dx-popup>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, { defineComponent, ref, computed } from "@cloudfun/core";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
import { DxPopup } from "devextreme-vue";
import { DxDataConfigurations } from "../../../../services/data-source-service";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import { DxDataGrid, DxEditing } from "devextreme-vue/data-grid";

import {
  DxSelectBox,
  DxNumberBox,
  DxTextArea,
  DxTextBox,
  DxDropDownBox,
  DxTreeView,
  DxTagBox,
  DxSwitch,
  DxButton,
  DxLoadPanel,
} from "devextreme-vue";

import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";

export default defineComponent({
  components: {
    FileUploader,
    DxPopup,
    DxForm,
    DxSelectBox,
    DxNumberBox,
    DxTextArea,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxTextBox,
    DxDropDownBox,
    DxTreeView,
    DxTagBox,
    DxLabel,
    DxSwitch,
    DxButton,
    DxRequiredRule,
    DxLoadPanel,
    SelectBox,
    DxLabel,
    DxDataGrid,
    DxEditing,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const uploader = ref({});
    const files = ref([]);
    const formData = ref({});
    const modal = ref({});
    const editImage = ref({});
    const image = ref({});
    const canEdit = ref(true);
    const activityDataSource = DxDataConfigurations.ActivityDataConfiguration({
      selectExpr: ["Id", "Name"],
    });

    const title = "新增銷售條件";

    const gridCoumns = [
      {
        dataField: "Qty",
        caption: "級距數量",
      },
      {
        dataField: "Discount",
        caption: "折扣值",
      },
      {
        dataField: "Month",
        caption: "適用月份",
      },
    ];

    return {
      modal,
      image,
      uploader,
      formData,
      files,
      title,
      editImage,
      canEdit,
      activityDataSource,
      gridCoumns,
    };
  },

  watch: {
    data(newValue, oldValue) {
      this.formData = newValue;
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },

    onSubmit(e) {
      console.log("formData", this.formData);
      //   const params = {
      //     insertRows: [],
      //     updateRows: [],
      //   };

      //   params.insertRows = [this.formData];
      // if (this.formData.Id === 0) params.insertRows = [this.formData];
      // else params.updateRows = [this.formData];

      const action = () => {
        this.formData = {};
        this.$emit("update:show", false);
      };

      if (this.$attrs.onSave) this.$emit("save", this.formData, action);
      else action();

      e.preventDefault();
    },
  },
});
</script>

<style scoped></style>
