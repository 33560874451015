
import { defineComponent, ref, PropType } from "@cloudfun/core";
import FileUpload, { VueUploadItem } from "vue-upload-component";
import Jimp from "jimp";

export default defineComponent({
  components: {
    FileUpload,
  },
  props: {
    id: String,
    name: String,
    title: String,
    droppable: { type: Boolean, default: true },
    accept: String,
    extensions: [Array, String, RegExp],
    previewable: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    limitedWidth: { type: Number, default: 0 },
    limitedHeight: { type: Number, default: 0 },
    maximumSize: { type: Number, default: 0 },
    timeout: { type: Number, default: 0 },
    maximumCount: Number,
    action: { type: [String, Promise] },
    headers: Object,
    parameters: Object,
    defaultImage: String,
    autoUpload: Boolean,
    mode: {
      type: String as PropType<"grid" | "input" | "image">,
      default: "grid",
    },
    modelValue: { type: Object as PropType<string | string[]> },
  },
  setup() {
    const instance = ref<any>({});
    const previewSrc = ref("");
    const files = ref<VueUploadItem[]>([]);
    const value = ref<string | string[] | undefined>();

    return {
      instance,
      previewSrc,
      files,
      value,
      loading: ref(false),
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    modelValue(current) {
      if (current !== this.value) {
        if (current) {
          if (typeof current === "string") {
            this.previewSrc = current;
            this.files = [
              { fileObject: false, id: this.instance.newId(), name: current },
            ];
          } else if (Array.isArray(current)) {
            if (current.length) this.previewSrc = current[0];
            this.files = current.map((e: string) => {
              return {
                fileObject: false,
                id: this.instance.newId(),
                name: e,
              } as VueUploadItem;
            });
          }
        } else {
          this.previewSrc = "";
          this.files = [];
        }
        this.value = this.computeValue();
        this.$emit("update:modelValue", this.value);
      }
    },
    files() {
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
    },
  },
  mounted() {
    if (this.modelValue) {
      if (typeof this.modelValue === "string") {
        this.previewSrc = this.modelValue;
        this.files = [
          {
            fileObject: false,
            id: this.instance.newId(),
            name: this.modelValue,
          },
        ];
      } else if (Array.isArray(this.modelValue)) {
        if (this.modelValue.length) this.previewSrc = this.modelValue[0];
        this.files = this.modelValue.map((e) => {
          return {
            fileObject: false,
            id: this.instance.newId(),
            name: e,
          } as VueUploadItem;
        });
      }
      this.value = this.computeValue();
    }
  },
  methods: {
    computeValue(): string | string[] | undefined {
      switch (this.mode) {
        case "grid":
          return this.files
            .filter((e: VueUploadItem) => e.name)
            .map((e: VueUploadItem) => {
              return e.response?.payload?.length
                ? e.response.payload[0]
                : e.name;
            });
        default:
          if (!this.files.length) return undefined;
          if (this.files[0]?.response?.payload?.length)
            return this.files[0].response.payload[0];
          return this.files[0]?.name;
      }
    },
    formatNumber(number: number) {
      let count = 0;
      while (number > 1000 && count < 9) {
        number /= 1000;
        count++;
      }
      let formatedNumber = "" + number.toFixed(2);
      if (formatedNumber.endsWith(".00"))
        formatedNumber = formatedNumber.substr(0, formatedNumber.length - 3);
      switch (count) {
        case 1:
          return `${formatedNumber}K`;
        case 2:
          return `${formatedNumber}M`;
        case 3:
          return `${formatedNumber}G`;
        case 4:
          return `${formatedNumber}T`;
        case 5:
          return `${formatedNumber}P`;
        case 6:
          return `${formatedNumber}E`;
        case 7:
          return `${formatedNumber}Z`;
        case 8:
          return `${formatedNumber}Y`;
        default:
          return `${formatedNumber}`;
      }
    },
    filter(
      current: any,
      original: any,
      prevent: (prevent: boolean) => boolean
    ) {
      this.$emit("filter", current, original, prevent);
    },
    input(current: any, original: any) {
      const URL = window.URL || window.webkitURL;
      if (!URL || !URL.createObjectURL) return;
      this.$emit("input", current, original);
      // revoke object url
      if (original?.file?.url && current?.file?.url !== original.file.url)
        URL.revokeObjectURL(original.file.url);
      // add file
      if (current && !original && current.fileObject) {
        let objectUrl = URL.createObjectURL(current.file);
        if (
          current?.file?.type &&
          typeof current.file.type.startsWith &&
          current.file.type.startsWith("image/")
        ) {
          const image = new Image();
          image.src = objectUrl;
          current.file.url = objectUrl;
          this.$emit("load", current);
          this.loading = true;
          image.onload = () => {
            this.previewSrc =
              this.defaultImage ||
              `https://via.placeholder.com/${this.limitedWidth}x${this.limitedHeight}.png`;
            if (
              (this.limitedWidth && image.width !== this.limitedWidth) ||
              (this.limitedHeight && image.height !== this.limitedHeight)
            ) {
              Jimp.read(URL.createObjectURL(current.file))
                .then((jimp) => {
                  jimp.resize(
                    this.limitedWidth || Jimp.AUTO,
                    this.limitedHeight || Jimp.AUTO
                  );
                  jimp.getBufferAsync(jimp.getMIME()).then((buffer) => {
                    current.file = new Blob([buffer], { type: jimp.getMIME() });
                    objectUrl = URL.createObjectURL(current.file);
                    current.file.url = objectUrl;
                    this.previewSrc = objectUrl;
                    this.loading = false;
                    this.$emit("loaded", current);
                  });
                })
                .finally(() => {
                  if (this.autoUpload) {
                    setTimeout(() => {
                      const file = this.files.find(
                        (e: any) => e.id === current.id
                      );
                      if (file) {
                        file.active = true;
                        this.upload();
                      }
                    }, 500);
                  }
                });
            } else {
              if (this.autoUpload) {
                setTimeout(() => {
                  const file = this.files.find((e: any) => e.id === current.id);
                  if (file) {
                    file.active = true;
                    this.upload();
                  }
                }, 500);
              }
              this.previewSrc = objectUrl;
              this.loading = false;
              this.$emit("loaded", current);
            }
          };
          image.onerror = (event) => {
            this.loading = false;
            this.$emit("loadError", event);
          };
        }
      }
    },
    preview(file?: VueUploadItem) {
      this.previewSrc = (file?.file as any)?.url;
    },
    removeFile(file: VueUploadItem) {
      this.instance.remove(file);
      this.files = this.files.filter((e: VueUploadItem) => e !== file);
      if (!this.files.length) this.preview(undefined);
      else if (this.previewSrc === (file.file as any)?.url) {
        this.preview(this.files[0]);
      }
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
    },
    upload() {
      if (this.files.length) {
        const files = [...this.files];
        return Promise.all(
          files
            .filter((e) => e.active)
            .map(
              (file) =>
                new Promise<VueUploadItem>((resolve, reject) => {
                  this.instance.upload(file).then(
                    (response: any) => {
                      this.instance.update(file, {
                        active: false,
                        success: !file.error,
                      });
                      response.active = false;
                      response.success = true;
                      this.value = this.computeValue();
                      this.$emit("update:modelValue", this.value);
                      console.log("response: ", response);
                      this.$emit("uploaded", response);
                      resolve(response);
                    },
                    (error: any) => {
                      this.instance.update(file, {
                        active: false,
                        success: false,
                        error:
                          error.message || error.code || error.error || error,
                      });
                      this.$emit("uploadError", file, error);
                      reject(error);
                    }
                  );
                })
            )
        );
      }
    },
    reset() {
      this.previewSrc = "";
      this.files = [];
      this.value = this.computeValue();
      this.$emit("update:modelValue", this.value);
    },
  },
});
