
/* eslint-disable */
import CloudFun, {
  defineComponent,
  reactive,
  ref,
  Condition,
  Operator,
} from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import ChildModal from "./Child/Modal.vue";
import model from "@/models";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    Grid,
    ChildModal,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const masterRow = reactive({});
    const grid = ref<any>({});
    const permission = reactive({
      isCreate: model?.user.Permissions.includes("BaseDataCreate"),
      isEditing: model?.user.Permissions.includes("BaseDataUpdate"),
      isDelete: model?.user.Permissions.includes("BaseDataDelete"),
    });
    const detailTitle = ref({});
    const showChildModal = ref(false);
    const gridOptions = {
      title: "產品類別",
      canCreateRow: permission.isCreate,
      canUpdateRow: permission.isEditing,
      canDeleteRow: permission.isDelete,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },

      columns: [
        {
          field: "Number",
          title: "類別編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          width: "120",
          fixed: "left",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "類別名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "Auto",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Ordinal",
          title: "顯示排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Published",
          title: "顯示",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          slots: { default: "published" },
        },
        {
          field: "Id",
          title: "子類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "80",
          slots: { default: "child" },
        },
      ],
      promises: {
        query: model
          ? (params: any) => {
              if (params.condition) {
                params.condition.and("ParentId", Operator.IsNull);
              }
              params.type = 1;
              return model.dispatch("category/query", params);
            }
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("category/query", {
                keyword: grid.value.keyword,
              })
          : undefined,

        save: model
          ? (params: any) => {
              if (permission.isEditing || permission.isCreate)
                return model.dispatch("category/save", params);
              else return Promise.resolve();
            }
          : undefined,
      },
      modalConfig: { height: "370", width: "600" },
      treeConfig: { children: "Children" },
    };

    const singleWithTreeSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      // dropDownWidth: 600,
      // dropDownHeight: 478,
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Number",
          title: "編號",
          treeNode: true,
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        },
      },
      treeConfig: { children: "Children" },
    };

    const formOptions = {
      items: [
        {
          field: "Number",
          title: "類別編號",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Name",
          title: "類別名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Parent.Name",
          title: "父類別",
          span: 24,
          slots: { default: "form-category" },
        },
        {
          field: "Ordinal",
          title: "顯示排序",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              type: "integer",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Published",
          title: "顯示",
          span: 24,
          itemRender: {
            name: "$switch",
            props: {
              openLabel: "顯示",
              closeLabel: "關閉",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入類別名稱",
          },
        ],
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入類別編號",
          },
        ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const initData = (row: any) => {
      row.Id = 0;
      row.Name = "";
      row.Number = "";
      row.Ordinal = 0;
      row.Published = false;
      row.Type = 1;
    };

    // onMounted(() => {
    //   console.log("");
    // });

    const switchChange = async (row: any, $event: any) => {
      const payload = await model?.dispatch("category/find", row.Id);
      if (payload) {
        payload.Published = $event.value;
        await model?.dispatch("category/update", payload);
      }
    };

    return {
      initData,
      switchChange,
      masterRow,
      detailTitle,
      grid,
      gridOptions,
      formOptions,
      showChildModal,
      singleWithTreeSelectOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      permission,
    };
  },
  methods: {
    onGridModalResize(size: any) {
      // this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("category/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    onGridCurrentRowChanged(row: any) {
      //   this.masterRow = row;
      // this.detailTitle = `${this.masterRow.Number} / ${this.masterRow.Name}`;
    },
    addChild(row: any) {
      console.log(row);
      Object.assign(this.masterRow, {
        Name: "",
        Number: "",
        Parent: { id: row.Id, Name: row.Name },
        ParentId: row.Id,
        Ordinal: 0,
        Published: true,
        Type: 1,
      });
      this.showChildModal = true;
    },
    childModalClose(el: any) {
      console.log("close", el);
      this.showChildModal = false;
    },
    childSubmit(data: any) {
      console.log("data", data);
      debugger;
      model.dispatch("category/insert", data).then(
        (payload) => {
          if (payload) {
            this.grid.reload();
            this.showChildModal = false;
          }
        },
        (reason) => {
          CloudFun.send("error", { subject: "刪除失敗", content: reason });
        }
      );
    },
  },
});
