<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">訂單管理</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 h-full">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <!-- <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
            >
              搜尋
            </button>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <cf-data-grid
          ref="grid"
          :options="gridOptions"
          :show-border="true"
        ></cf-data-grid>
      </div>

      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <detail :id="rowId" ref="detail"></detail>
        <!-- <cf-data-grid :options="options" :show-border="true"></cf-data-grid> -->
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
// import DxButton from "devextreme-vue/button";
// import { DxDataGrid } from "devextreme-vue/data-grid";

import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import createDataSource from "@/utils/devextreme";
import { DxDataSources } from "../../services/data-source-service";
import Detail from "./Detail.vue";
export default defineComponent({
  components: { Detail },
  setup() {
    const grid = ref({});
    const rowId = ref(0);
    const detail = ref({});
    const defaultDataSourceFilter = [];
    const model = CloudFun.current?.model;
    const permission = reactive({
      isExport: model?.user.Permissions.includes("OrderExport"),
      isDelete: model?.user.Permissions.includes("OrderDelete"),
    });
    const generateQueryConditions = (startDate, endDate) => {
      const conditions = [defaultDataSourceFilter];
      if (startDate) {
        conditions.push(["Time", ">=", startDate]);
      }
      if (endDate) {
        conditions.push(["Time", "<", endDate]);
      }

      return conditions;
    };

    const OrderDataSource = DxDataSources.OrderDataSource({
      filterExpr: defaultDataSourceFilter,
    });

    const gridColumns = [
      // {
      //   dataField: "Id",
      //   label: { text: "Id" },
      // },
      { dataField: "Number", label: { text: "訂單編號" } },
      {
        dataField: "CreatedTime",
        label: { text: "時間" },
        sortOrder: "desc",
        dataType: "datetime",
      },

      { dataField: "Store.Organization.Number", label: { text: "經銷商代號" } },
      { dataField: "Store.Organization.Name", label: { text: "經銷商名稱" } },
      {
        dataField: "Store.Organization.Grade.Name",
        label: { text: "經銷商級別" },
      },
      {
        dataField: "Store.Organization.SaleAres.Name",
        label: { text: "銷售區域" },
      },
      {
        dataField: "Store.Organization.Sales.Person.Name",
        label: { text: "負責業務" },
      },
      // {
      //   dataField: "OrderDate",
      //   label: { text: "ERP訂單日期" },
      //   dataType: "date",
      // },
      {
        dataField: "Amount",
        label: { text: "預訂金額" },
        format: { type: "currency", precision: 0 },
      },
    ];

    const gridOptions = {
      dataSource: OrderDataSource.dataSource,
      canDelete: permission.isDelete,
      canCreate: false,
      height: "auto",
      columns: gridColumns,
      onRowClick: onRowClick,
      focusedEnabled: false,
      remoteOperations: true,
      // summarySettings: {
      //   items: [
      //     {
      //       type: "total",
      //       column: "Amount",
      //       summaryType: "sum",
      //       skipEmptyValues: true,
      //       displayFormat: "小計: {0}",
      //       valueFormat: { type: "currency", precision: 0 },
      //     },
      //   ],
      // },
      // onInitialized: this.onGridInitialized,
      // onCellPrepared: this.onCellPrepared,
      // onContextMenuPreparing: this.onContextMenuPreparing,
      // stateSave: true,
      // stateStoring: {
      //   enabled: true,
      //   storageKey: this.$route.name,
      // },
    };

    function onRowClick({ data }) {
      // this.loadData(key);
      // debugger;
      rowId.value = data.Id;
      console.log("detail", detail);
      detail.value.reload(
        data.Id,
        `${data.Number}_${data.Store.Number} ${data.Store.Name}`
      );
    }

    return {
      grid,
      rowId,
      defaultDataSourceFilter,
      generateQueryConditions,
      OrderDataSource,
      gridOptions,
      onRowClick,
      permission,
      detail,
    };
  },
  methods: {},
});
</script>

<style scoped></style>
