
import { defineComponent, onMounted, reactive, watch, nextTick } from "vue";
import CloudFun, { Model, ref } from "@cloudfun/core";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import { required } from "@vuelidate/validators";

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const permission = reactive({
      isCreate: model?.user.Permissions.includes("ProductCreate"),
      isEditing: model?.user.Permissions.includes("ProductUpdate"),
    });
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        items: [
          "sourceEditing",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "alignment",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const entity = reactive({ Id: 0, Content: "" });

    watch(
      () => props.id,
      () => {
        nextTick(() => {
          if (props.id > 0) getContent(props.id);
        });
      }
    );

    const getContent = (id: any) => {
      entity.Id = 0;
      entity.Content = "";
      model
        ?.dispatch("product/findContent", id)
        .then((response) => {
          entity.Id = response.Id;
          entity.Content = response.Content;
          console.log("current entity", entity);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const PostObject = () => {
      model?.dispatch(`product/updateContent`, entity).then(
        (paylod) => {
          Object.assign(entity, paylod);
          CloudFun.send("info", {
            subject: "儲存成功",
            content: "組織架構儲存成功！",
          });
        },
        (failure) =>
          CloudFun.send("error", { subject: "儲存失敗", content: failure })
      );
    };

    onMounted(() => {
      //   getAbout().then((data: any) => {
      //     Object.assign(entity, data);
      //   });
    });

    return {
      entity,
      PostObject,
      classicEditor,
      editorConfig,
      permission,
    };
  },
});
