<template>
  <vxe-pulldown
    class="w-full"
    :ref="(el) => (instance = el)"
    :transfer="transfer"
  >
    <template #default>
      <vxe-input
        class="w-full"
        :placeholder="placeholder || '請選擇色彩'"
        :clearable="true"
        :disabled="disabled"
        v-model="label"
        @focus="instance.showPanel()"
        @keyup="onKeywordChanged"
        @clear="onClear"
      >
        <template #prefix>
          <div
            class="border rounded w-5 h-5"
            :style="{ backgroundColor: value }"
          ></div>
        </template>
      </vxe-input>
    </template>
    <template #dropdown>
      <div
        class="border rounded overflow-auto"
        :style="{
          maxWidth:
            typeof dropdownMaxWidth !== 'string'
              ? `${dropdownMaxWidth}px`
              : dropdownMaxWidth || 'unset',
          maxHeight:
            typeof dropdownMaxHeight !== 'string'
              ? `${dropdownMaxHeight}px`
              : dropdownMaxHeight || 'unset',
        }"
      >
        <span class="border rounded-b m-1 p-1 text-white bg-gray-500"
          >Html Color Codes</span
        >
        <div class="flex flex-wrap pl-1 pt-1 justify-start content-startr">
          <div
            v-for="item in colors"
            :key="item.value"
            :title="item.label"
            :style="{ backgroundColor: item.value }"
            class="mr-1 my-1 border rounded-lg h-10 w-10 text-center text-black text-xl"
            @click="onChange(item)"
          >
            <FontAwesome
              v-if="item.value === value"
              icon="check-circle"
              type="fas"
              class="w-5 h-5 mt-2 ml-2 border-0 rounded-full text-white bg-blue-500"
            />
          </div>
        </div>
      </div>
    </template>
  </vxe-pulldown>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "@cloudfun/core";
import { watch } from "vue";
import { VxePulldownInstance } from "vxe-table";

interface ColorCode {
  label: string;
  value: string;
}

export default defineComponent({
  props: {
    modelValue: String,
    transfer: Boolean,
    placeholder: String,
    dropdownMaxWidth: [Number, String],
    dropdownMaxHeight: [Number, String],
    customColors: {
      type: Object as PropType<
        (keyword?: string) => Promise<Array<ColorCode>> | Array<ColorCode>
      >,
    },
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const htmlColors: ColorCode[] = [
      { label: "AliceBlue", value: "AliceBlue" },
      { label: "AntiqueWhite", value: "AntiqueWhite" },
      { label: "Aqua", value: "Aqua" },
      { label: "Azure", value: "Azure" },
      { label: "Beige", value: "Beige" },
      { label: "Bisque", value: "Bisque" },
      { label: "Black", value: "Black" },
      { label: "BlanchedAlmond", value: "BlanchedAlmond" },
      { label: "Blue", value: "Blue" },
      { label: "BlueViolet", value: "BlueViolet" },
      { label: "Brown", value: "Brown" },
      { label: "BurlyWood", value: "BurlyWood" },
      { label: "CadetBlue", value: "CadetBlue" },
      { label: "Chartreuse", value: "Chartreuse" },
      { label: "Chocolate", value: "Chocolate" },
      { label: "Coral", value: "Coral" },
      { label: "CornflowerBlue", value: "CornflowerBlue" },
      { label: "Cornsilk", value: "Cornsilk" },
      { label: "Crimson", value: "Crimson" },
      { label: "Cyan", value: "Cyan" },
      { label: "DarkBlue", value: "DarkBlue" },
      { label: "DarkCyan", value: "DarkCyan" },
      { label: "DarkGoldenRod", value: "DarkGoldenRod" },
      { label: "DarkGray", value: "DarkGray" },
      { label: "DarkGrey", value: "DarkGrey" },
      { label: "DarkGreen", value: "DarkGreen" },
      { label: "DarkKhaki", value: "DarkKhaki" },
      { label: "DarkMagenta", value: "DarkMagenta" },
      { label: "DarkOliveGreen", value: "DarkOliveGreen" },
      { label: "DarkOrange", value: "DarkOrange" },
      { label: "DarkOrchid", value: "DarkOrchid" },
      { label: "DarkRed", value: "DarkRed" },
      { label: "DarkSalmon", value: "DarkSalmon" },
      { label: "DarkSeaGreen", value: "DarkSeaGreen" },
      { label: "DarkSlateBlue", value: "DarkSlateBlue" },
      { label: "DarkSlateGray", value: "DarkSlateGray" },
      { label: "DarkSlateGrey", value: "DarkSlateGrey" },
      { label: "DarkTurquoise", value: "DarkTurquoise" },
      { label: "DarkViolet", value: "DarkViolet" },
      { label: "DeepPink", value: "DeepPink" },
      { label: "DeepSkyBlue", value: "DeepSkyBlue" },
      { label: "DimGray", value: "DimGray" },
      { label: "DimGrey", value: "DimGrey" },
      { label: "DodgerBlue", value: "DodgerBlue" },
      { label: "FireBrick", value: "FireBrick" },
      { label: "FloralWhite", value: "FloralWhite" },
      { label: "ForestGreen", value: "ForestGreen" },
      { label: "Fuchsia", value: "Fuchsia" },
      { label: "Gainsboro", value: "Gainsboro" },
      { label: "GhostWhite", value: "GhostWhite" },
      { label: "Gold", value: "Gold" },
      { label: "GoldenRod", value: "GoldenRod" },
      { label: "Gray", value: "Gray" },
      { label: "Grey", value: "Grey" },
      { label: "Green", value: "Green" },
      { label: "GreenYellow", value: "GreenYellow" },
      { label: "HoneyDew", value: "HoneyDew" },
      { label: "HotPink", value: "HotPink" },
      { label: "IndianRed", value: "IndianRed" },
      { label: "Indigo", value: "Indigo" },
      { label: "Ivory", value: "Ivory" },
      { label: "Khaki", value: "Khaki" },
      { label: "Lavender", value: "Lavender" },
      { label: "LavenderBlush", value: "LavenderBlush" },
      { label: "LawnGreen", value: "LawnGreen" },
      { label: "LemonChiffon", value: "LemonChiffon" },
      { label: "LightBlue", value: "LightBlue" },
      { label: "LightCoral", value: "LightCoral" },
      { label: "LightCyan", value: "LightCyan" },
      { label: "LightGoldenRodYellow", value: "LightGoldenRodYellow" },
      { label: "LightGray", value: "LightGray" },
      { label: "LightGrey", value: "LightGrey" },
      { label: "LightGreen", value: "LightGreen" },
      { label: "LightPink", value: "LightPink" },
      { label: "LightSalmon", value: "LightSalmon" },
      { label: "LightSeaGreen", value: "LightSeaGreen" },
      { label: "LightSkyBlue", value: "LightSkyBlue" },
      { label: "LightSlateGray", value: "LightSlateGray" },
      { label: "LightSlateGrey", value: "LightSlateGrey" },
      { label: "LightSteelBlue", value: "LightSteelBlue" },
      { label: "LightYellow", value: "LightYellow" },
      { label: "Lime", value: "Lime" },
      { label: "LimeGreen", value: "LimeGreen" },
      { label: "Linen", value: "Linen" },
      { label: "Magenta", value: "Magenta" },
      { label: "Maroon", value: "Maroon" },
      { label: "MediumAquaMarine", value: "MediumAquaMarine" },
      { label: "MediumBlue", value: "MediumBlue" },
      { label: "MediumOrchid", value: "MediumOrchid" },
      { label: "MediumPurple", value: "MediumPurple" },
      { label: "MediumSeaGreen", value: "MediumSeaGreen" },
      { label: "MediumSlateBlue", value: "MediumSlateBlue" },
      { label: "MediumSpringGreen", value: "MediumSpringGreen" },
      { label: "MediumTurquoise", value: "MediumTurquoise" },
      { label: "MediumVioletRed", value: "MediumVioletRed" },
      { label: "MidnightBlue", value: "MidnightBlue" },
      { label: "MintCream", value: "MintCream" },
      { label: "MistyRose", value: "MistyRose" },
      { label: "Moccasin", value: "Moccasin" },
      { label: "NavajoWhite", value: "NavajoWhite" },
      { label: "Navy", value: "Navy" },
      { label: "OldLace", value: "OldLace" },
      { label: "Olive", value: "Olive" },
      { label: "OliveDrab", value: "OliveDrab" },
      { label: "Orange", value: "Orange" },
      { label: "OrangeRed", value: "OrangeRed" },
      { label: "Orchid", value: "Orchid" },
      { label: "PaleGoldenRod", value: "PaleGoldenRod" },
      { label: "PaleGreen", value: "PaleGreen" },
      { label: "PaleTurquoise", value: "PaleTurquoise" },
      { label: "PaleVioletRed", value: "PaleVioletRed" },
      { label: "PapayaWhip", value: "PapayaWhip" },
      { label: "PeachPuff", value: "PeachPuff" },
      { label: "Peru", value: "Peru" },
      { label: "Pink", value: "Pink" },
      { label: "Plum", value: "Plum" },
      { label: "PowderBlue", value: "PowderBlue" },
      { label: "Purple", value: "Purple" },
      { label: "RebeccaPurple", value: "RebeccaPurple" },
      { label: "Red", value: "Red" },
      { label: "RosyBrown", value: "RosyBrown" },
      { label: "RoyalBlue", value: "RoyalBlue" },
      { label: "SaddleBrown", value: "SaddleBrown" },
      { label: "Salmon", value: "Salmon" },
      { label: "SandyBrown", value: "SandyBrown" },
      { label: "SeaGreen", value: "SeaGreen" },
      { label: "SeaShell", value: "SeaShell" },
      { label: "Sienna", value: "Sienna" },
      { label: "Silver", value: "Silver" },
      { label: "SkyBlue", value: "SkyBlue" },
      { label: "SlateBlue", value: "SlateBlue" },
      { label: "SlateGray", value: "SlateGray" },
      { label: "SlateGrey", value: "SlateGrey" },
      { label: "Snow", value: "Snow" },
      { label: "SpringGreen", value: "SpringGreen" },
      { label: "SteelBlue", value: "SteelBlue" },
      { label: "Tan", value: "Tan" },
      { label: "Teal", value: "Teal" },
      { label: "Thistle", value: "Thistle" },
      { label: "Tomato", value: "Tomato" },
      { label: "Turquoise", value: "Turquoise" },
      { label: "Violet", value: "Violet" },
      { label: "Wheat", value: "Wheat" },
      { label: "White", value: "White" },
      { label: "WhiteSmoke", value: "WhiteSmoke" },
      { label: "Yellow", value: "Yellow" },
      { label: "YellowGreen", value: "YellowGreen" },
    ];
    const instance = ref({} as VxePulldownInstance);
    const additionColors = ref<Array<ColorCode>>([]);
    const colors = ref(htmlColors);
    const label = ref("");
    const value = ref(props.modelValue || "");

    watch(
      () => props.modelValue,
      (newValue) => {
        if (value.value !== newValue) {
          const allColors = [...htmlColors, ...additionColors.value];
          const color = allColors.find((e) =>
            new RegExp(`^${newValue}$`, "i").test(e.value)
          );
          if (color) {
            label.value = color.label;
            if (label.value)
              colors.value = allColors.filter((item) =>
                new RegExp(label.value, "i").test(item.label)
              );
            if (value.value !== color.value) {
              value.value = color.value;
              emit("update:modelValue", value.value);
              emit("change", value.value);
            }
          }
        }
      }
    );

    return {
      instance,
      htmlColors,
      additionColors,
      colors,
      label,
      value,
    };
  },
  async mounted() {
    this.additionColors = this.customColors
      ? Array.isArray(this.customColors)
        ? this.customColors
        : await this.customColors()
      : [];
    this.colors = [...this.htmlColors, ...this.additionColors];
    if (this.value) {
      const color = this.colors.find((e) =>
        new RegExp(`^${this.value}$`, "i").test(e.value)
      );
      if (color) {
        this.label = color.label;
        this.colors = this.label
          ? this.colors.filter((item) =>
              new RegExp(this.label, "i").test(item.label)
            )
          : this.colors;
        if (this.value !== color.value) {
          this.value = color.value;
          this.$emit("update:modelValue", this.value);
          this.$emit("change", this.value);
        }
      }
    }
  },
  methods: {
    async onKeywordChanged() {
      const colors = [...this.htmlColors, ...this.additionColors];
      this.colors = this.label
        ? colors.filter((item) => new RegExp(this.label, "i").test(item.label))
        : colors;
    },
    onChange(item: any) {
      this.label = item.label;
      this.value = item.value;
      this.instance.hidePanel().then(() => {
        const colors = [...this.htmlColors, ...this.additionColors];
        this.colors = this.label
          ? colors.filter((item) =>
              new RegExp(this.label, "i").test(item.label)
            )
          : colors;
      });
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    onClear() {
      this.label = "";
      this.value = "";
      const colors = [...this.htmlColors, ...this.additionColors];
      this.colors = this.label
        ? colors.filter((item) => new RegExp(this.label, "i").test(item.label))
        : colors;
    },
  },
});
</script>
