<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">購物車記錄</h2>
    </div>
    <div class="intro-y box p-5 mt-5 h-full">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div> -->
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label w-24"
              >截止時間</label
            >
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectedEndDate"
              @change="grid.refresh"
            >
              <option value="">請選擇下單截止時間</option>
              <option
                v-for="(option, index) in times"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label w-28"
              >經銷商名稱</label
            >
            <input
              type="text"
              v-model="organizationName"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="輸入名稱關鍵字"
            />
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label w-28"
              >產品分類</label
            >
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectedErpCategory"
            >
              <option value="">請選擇分類</option>
              <option
                v-for="(option, index) in erpCategories"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label w-24"
              >下單時間</label
            >
            <Litepicker
              v-model="daterange"
              :options="{
                autoApply: true,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                format: 'YYYY-MM-DD',
                showWeekNumbers: true,
                lang: 'zh-TW',
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true,
                },
              }"
              class="input w-56 border block mx-auto"
            />
          </div> -->
          <!-- <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
            >
              搜尋
            </button>
          </div> -->
        </div>
      </div>
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start pt-2">
        <div class="xl:flex sm:mr-auto">
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div> -->
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label w-full"
              >產品編號或貨號</label
            >
            <input
              type="text"
              v-model="productNumber"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="輸入產品編號或貨號"
            />
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label w-28"
              >銷售區域</label
            >
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectedArea"
            >
              <option value="">請選擇區域</option>
              <option
                v-for="(option, index) in saleAreas"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <label for="horizontal-form-2" class="form-label sm:w-20"
              >負責業務</label
            >
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectedSales"
              @change="grid.refresh"
            >
              <option value="">請選擇業務</option>
              <option
                v-for="(option, index) in sales"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </div> -->
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="doQuery"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 h-full">
      <!-- <div class="flex flex-col sm:flex-row items-center">
                <label class="w-full sm:w-20 sm:text-right sm:mr-5"
                  >Email</label
                >
                <input
                  type="email"
                  class="input w-full border mt-2 flex-1"
                  placeholder="example@gmail.com"
                />
              </div> -->

      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <dx-data-grid
          ref="grid"
          key-expr="OrderItemId"
          :show-borders="true"
          :data-source="OrderRecordDataSource.dataSource"
          column-width="auto"
          :columns="columns"
          show-column-lines="true"
          show-row-lines="true"
          width="100%"
          :remote-operations="true"
          row-alternation-enabled="true"
          @initialized="onGridInitialized"
          :on-toolbar-preparing="onToolbarPreparing"
        >
          <dx-header-filter :visible="false" />
          <dx-search-panel :visible="false"></dx-search-panel>
          <dx-paging :page-size="pageSettings.pageSize" />
          <dx-group-panel :visible="false" />
          <dx-pager
            v-if="pageSettings.showPager"
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSettings.pagerSizes"
            :show-info="true"
          />
          <!-- <dx-summary>
            <template v-for="(item, i) in totalItems" :key="`summary_${i}`">
              <dx-total-item
                :column="item.column"
                :summary-type="item.summaryType"
                :skip-empty-values="item.skipEmptyValues"
                :value-format="item.valueFormat"
                :display-format="item.displayFormat"
                :align-by-column="item.alignByColumn"
              ></dx-total-item>
            </template>
          </dx-summary> -->
          <dx-export
            :enabled="exportSettings.enabled"
            :allow-export-selected-data="exportSettings.allowExportSelectedData"
            :file-name="exportSettings.fileName"
            :texts="exportSettings.texts"
            :customize-excel-cell="exportSettings.customizeExcelCell"
          />
        </dx-data-grid>
      </div>

      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <!-- <detail :id="rowId" ref="detail"></detail> -->
        <!-- <cf-data-grid :options="options" :show-border="true"></cf-data-grid> -->
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import CloudFun, { defineComponent, reactive, ref } from "@cloudfun/core";
import { DxDataSources } from "../../services/data-source-service";
import CustomStore from "devextreme/data/custom_store";
import {
  DxDataGrid,
  DxColumnFixing,
  DxSummary,
  DxTotalItem,
  DxPaging,
  DxPager,
  DxHeaderFilter,
  DxSearchPanel,
  DxGroupPanel,
  DxExport,
} from "devextreme-vue/data-grid";
export default defineComponent({
  components: {
    DxDataGrid,
    // DxSummary,
    // DxTotalItem,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxSearchPanel,
    DxGroupPanel,
    DxExport,
  },
  props: {
    id: {
      type: Number,
      require: true,
    },
  },

  setup(props) {
    const grid = ref({});
    const dataSource = reactive({});
    const columns = ref([]);
    const totalItems = ref([]);
    const gridInstance = ref({});
    const daterange = ref("");
    const model = CloudFun.current?.model;
    const defaultDataSourceFilter = [];
    const times = ref([]);
    const sales = ref([]);
    const saleAreas = ref([]);
    const erpCategories = ref([]);
    const selectedEndDate = ref("");
    const organizationName = ref("");
    const productNumber = ref("");
    const selectedArea = ref("");
    const selectedSales = ref("");
    const selectedErpCategory = ref("");
    const filter = ref("");
    const permission = reactive({
      isExport: model?.user.Permissions.includes("OrderExport"),
      isErpExport: model?.user.Permissions.includes("OrderErpExport"),
    });

    daterange.value = "";

    model?.dispatch("schedule/getEndDate", null).then(
      (payload) => {
        console.log("payload", payload);
        times.value = payload.map((item) => {
          return { label: item, value: item };
        });
      },
      (failure) => {
        console.log("error", failure);
      }
    );

    model?.dispatch("sales/query").then(
      (payload) => {
        console.log("sales", payload);
        sales.value = payload.data.map((el) => {
          return {
            value: el.Person.Name,
            label: el.Person.Name,
            saleAreaId: el.SaleAreaId,
          };
        });
      },
      (failure) => {
        CloudFun.send("error", { subject: "操作失敗！", content: failure });
      }
    );

    model?.dispatch("saleArea/query").then(
      (payload) => {
        console.log("saleAreas", payload);
        saleAreas.value = payload.data.map((el) => {
          return {
            value: el.Name,
            label: el.Name,
          };
        });
      },
      (failure) => {
        CloudFun.send("error", { subject: "操作失敗！", content: failure });
      }
    );

    model?.dispatch("erpProductCategory/query").then(
      (payload) => {
        console.log("erpProductCategory", payload);
        erpCategories.value = payload.data.map((el) => {
          return {
            value: el.Id,
            label: el.Name,
          };
        });
      },
      (failure) => {
        CloudFun.send("error", { subject: "操作失敗！", content: failure });
      }
    );

    const gridColumns = [
      {
        dataField: "ShoppingCartRealTime",
        caption: "購物車時間",
        allowSearch: true,
      },
      {
        dataField: "StoreNumber",
        caption: "經銷商代號",
        allowSearch: true,
      },
      {
        dataField: "StoreName",
        caption: "經銷商名稱",
        allowSearch: true,
        // sortIndex: 0,
        // sortOrder: "asc",
      },
      {
        dataField: "ShortName",
        caption: "經銷商簡稱",
        allowSearch: true,
      },
      {
        dataField: "GradeName",
        caption: "經銷商等級",
        allowSearch: true,
      },
      {
        dataField: "SaleAreaName",
        caption: "銷售區域",
        allowSearch: true,
      },
      {
        dataField: "SalesName",
        caption: "業務",
        allowSearch: true,
      },
      // {
      //   dataField: "OrderRealTime",
      //   caption: "下單時間",
      //   allowSearch: true,
      // },
      {
        dataField: "EndDate",
        caption: "下單截止日",
      },
      // {
      //   dataField: "OrderDate",
      //   caption: "ERP訂單日期",
      //   allowSearch: true,
      //   dataType: "date",
      // },
      {
        dataField: "ProductNumber",
        caption: "產品編號",
        allowSearch: true,
      },
      {
        dataField: "ProductName",
        caption: "產品名稱",
        allowSearch: true,
      },
      {
        dataField: "ErpProductCategoryName",
        caption: "產品大類",
        allowSearch: true,
      },
      {
        dataField: "ColorCode",
        caption: "色號",
        allowSearch: true,
      },
      {
        dataField: "ColorName",
        caption: "顏色",
        allowSearch: true,
      },
      {
        dataField: "SizeName",
        caption: "尺寸",
        allowSearch: true,
      },
      {
        dataField: "GoodsNumber",
        caption: "貨號",
        allowSearch: true,
        // sortIndex: 1,
        // sortOrder: "asc",
      },
      {
        dataField: "JANCode",
        caption: "JAN",
        allowSearch: true,
      },

      {
        dataField: "PurchasePrice",
        caption: "採購單價",
        format: { type: "currency", precision: 0 },
      },
      {
        dataField: "Subtotal",
        caption: "總採購量",
        format: { type: "fixedPoint", precision: 0 },
      },
      {
        dataField: "PurchaseAmount",
        caption: "採購金額",
        format: { type: "currency", precision: 0 },
      },
    ];

    const OrderRecordDataSource = DxDataSources.ShoppingCartRecordDataSource(
      {
        queryString: filter.value,
        onLoaded: (resp) => {
          // console.log("resp", resp);

          if (resp && resp.data.length > 0) {
            const propertyNames = Object.getOwnPropertyNames(resp.data[0]);
            var index = propertyNames.findIndex((s) => s.startsWith("20"));
            if (propertyNames) {
              columns.value = [];
              columns.value.push(...gridColumns);
              for (var i = index; i < propertyNames.length; i++) {
                if (propertyNames[i] === "Subtotal") continue;
                columns.value.push({
                  dataField: propertyNames[i],
                  allowSearch: false,
                  caption: propertyNames[i],
                });

                totalItems.value.push({
                  type: "total",
                  column: propertyNames[i],
                  summaryType: "sum",
                  displayFormat: "數量: {0}",
                  valueFormat: { type: "fixedPoint", precision: 0 },
                });

                // console.log("columns.push", propertyNames[i]);
              }
            }
          }
        },
      },
      {
        filterExpr: defaultDataSourceFilter,
      }
    );

    const pageSettings = reactive({
      showPager: true,
      pageSize: 10,
      pagerSizes: [10, 25, 50],
    });
    const exportSettings = reactive({
      enabled: permission.isExport,
      fileName: "購物車記錄",
      allowExportSelectedData: false,
      texts: { exportAll: "匯出 Excel" },
    });

    function onGridInitialized(e) {
      console.log("e", e);
      gridInstance.value = e.component;
    }

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        visible: permission.isExport,
        options: {
          icon: "refresh",
          onClick: () => {
            gridInstance.value.refresh();
          },
        },
      });
    }

    function doQuery() {
      const filterExp = [];

      if (selectedEndDate.value)
        filterExp.push(["orderDate", "=", selectedEndDate.value]);

      if (daterange.value)
        filterExp.push(["orderRealDate", "=", daterange.value]);

      if (organizationName.value)
        filterExp.push(["organizationName", "=", organizationName.value]);

      if (selectedArea.value)
        filterExp.push(["saleAreaName", "=", selectedArea.value]);
      if (selectedSales.value)
        filterExp.push(["salesName", "=", selectedSales.value]);
      if (productNumber.value)
        filterExp.push(["productNumber", "=", productNumber.value]);
      if (selectedErpCategory.value)
        filterExp.push([
          "erpProductCategoryId",
          "=",
          selectedErpCategory.value,
        ]);

      if (filterExp.length > 0) gridInstance.value.filter(filterExp);
      else gridInstance.value.clearFilter("dataSource");
    }

    return {
      grid,
      daterange,
      dataSource,
      columns,
      totalItems,
      pageSettings,
      exportSettings,
      gridColumns,
      OrderRecordDataSource,
      onToolbarPreparing,
      gridInstance,
      onGridInitialized,
      times,
      sales,
      saleAreas,
      doQuery,
      selectedEndDate,
      organizationName,
      productNumber,
      selectedArea,
      selectedSales,
      filter,
      erpCategories,
      selectedErpCategory,
      permission,

      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
});
</script>

<style scoped></style>
