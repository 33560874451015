<template>
  <div>
    <vxe-modal
      :title="title"
      transfer
      destroy-on-close
      v-bind="lastModalConfig"
      v-model="showModal"
      width="600"
      height="600"
      resize
      @close="close"
    >
      <template #default>
        <vxe-form
          title-width="100"
          title-align="right"
          v-bind="formOptions"
          :data="data"
          :rules="rules"
          @reset="reset"
          @submit="submit"
        >
          <vxe-form-item
            title="類別編號"
            field="Number"
            span="24"
            :item-render="{
              name: '$input',
              attrs: { placeholder: '輸入類別編號' },
            }"
          ></vxe-form-item>
          <vxe-form-item
            title="類別名稱"
            span="24"
            field="Name"
            :item-render="{
              name: '$input',
              attrs: { placeholder: '輸入類別編號' },
            }"
          ></vxe-form-item>
          <vxe-form-item
            title="父類別"
            span="24"
            field="ParentId"
            :item-render="{}"
          >
            <template #default="{ data }">
              <select-box
                class="mr-10 w-full"
                v-model="data.ParentId"
                v-bind="singleWithTreeSelectOptions"
              />
            </template>
          </vxe-form-item>
          <vxe-form-item
            title="排序值"
            span="24"
            field="Ordinal"
            :item-render="{
              name: '$input',
              props: { placeholder: '輸入排序值', type: 'integer' },
            }"
          ></vxe-form-item>
          <vxe-form-item
            title="顯示"
            span="24"
            field="Published"
            :item-render="{
              name: '$switch',
              props: { openLabel: '顯示', closeLabel: '隱藏' },
            }"
          ></vxe-form-item>
          <vxe-form-item align="center" span="24">
            <template #default>
              <vxe-button type="submit" status="primary">確認</vxe-button>
              <vxe-button type="reset">關閉</vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </template>
    </vxe-modal>
  </div>
</template>

<script lang="ts">
import CloudFun, {
  defineComponent,
  ref,
  Condition,
  Operator,
} from "@cloudfun/core";
import { watch } from "vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    SelectBox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isModalPopup: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const showModal = ref(false);
    const title = ref("");
    watch(
      () => props.isModalPopup,
      () => {
        console.log("showModal", props.isModalPopup);
        showModal.value = props.isModalPopup;
        title.value = `${props.data.Parent.Name}子類別`;
      }
    );

    const singleWithTreeSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      // dropDownWidth: 600,
      // dropDownHeight: 478,
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Number",
          title: "編號",
          treeNode: true,
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        },
      },
      treeConfig: { children: 'Children' },
    };

    const rules = {
      Name: [
        {
          type: "string",
          required: true,
          message: "請輸入類別名稱",
        },
      ],
      Number: [
        {
          type: "string",
          required: true,
          message: "請輸入類別編號",
        },
      ],
    };

    const reset = () => {
      //   console.log("reset");
      close();
    };

    const submit = () => {
      //   console.log("submit");
      emit("submit", props.data);
    };

    const close = () => {
      showModal.value = false;
      emit("close", showModal);
    };

    return {
      reset,
      submit,
      close,
      rules,
      title,
      showModal,
      singleWithTreeSelectOptions,
    };
  },
});
</script>

<style scoped></style>
