<template>
  <div class="flex">
    <div style="width: 200px;" class="mr-10 flex-initial">
      網格模式(複選): <br />
      <file-uploader 
        :ref="el => uploader = el"
        id="multipleFileDropListUploader"
        mode="grid"
        v-model="multipleFileDropListUploader"
      />
      <span>Value: {{ multipleFileDropListUploader }}</span>
    </div>
    <div style="width: 200px;" class="mr-10 flex-initial">
      圖像模式(單選)、限寬高僅點選: <br />
      <file-uploader 
        id="singleFileLimitSizeUploader"
        mode="image"
        :droppable="false"
        :limitedWidth="200"
        :limitedHeight="200"
        v-model="singleFileLimitSizeUploader"
      />
      <span>Value: {{ singleFileLimitSizeUploader }}</span>
    </div>
    <div style="width: 200px;" class="mr-10 flex-initial">
      輸入框模式(單選)、無預覽: <br />
      <file-uploader 
        id="singleFileDropInputUploader"
        mode="input"
        :previewable="false"
        v-model="singleFileDropInputUploader"
      />
      <span>Value: {{ singleFileDropInputUploader }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, Operator, ref } from "@cloudfun/core";
import FileUploader from "@/cloudfun/components/FileUploader.vue";

export default defineComponent({
  components: {
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model;
    const multipleFileDropListUploader = ref();
    const singleFileLimitSizeUploader = ref();
    const singleFileDropInputUploader = ref();
    const uploader = ref<any>({});
    return {
      multipleFileDropListUploader,
      singleFileLimitSizeUploader,
      singleFileDropInputUploader,
      uploader
    };
  },
  methods: {
  }
});
</script>
