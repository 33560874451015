<template>
  單選:
  <div class="flex">
    <div class="border-2" style="width: 200px; height: 200px;">
      原型
      <select-box v-bind="singleSelectOptions" v-model="currentValue" />
    </div>
    <div class="border-2" style="width: 200px; height: 200px;">
      搜尋框 
      <select-box v-bind="singleWithSearchSelectOptions" v-model="currentValue" />
    </div>
    <div class="border-2" style="width: 200px; height: 200px;">
      樹狀
      <select-box v-bind="singleWithTreeSelectOptions" v-model="currentValue2" @disallowSelect="onDisallowSelect" />
    </div>
  </div>
  複選
  <div class="flex">
    <div class="border-2" style="width: 200px; height: 200px;">
      原型
      <select-box v-bind="multiselectOptions" v-model="currentValue" />
    </div>
    <div class="border-2" style="width: 200px; height: 200px;">
      可新增
      <select-box v-bind="multiselectCanCreateOptions" v-model="currentValue" />
    </div>
    <div class="border-2" style="width: 200px; height: 200px;">
      樹狀
      <select-box v-bind="multiselectTagsOptions" v-model="currentValue2" @disallowSelect="onDisallowSelect" />
    </div>
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, Operator, ref } from "@cloudfun/core";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const singleSelectOptions: SelectBoxOptions = {
      rowId: "Id",
      placeholder: "選擇角色",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", { parentId: null, ...params }), // eslint-disable-line
      },
    };
    const singleWithSearchSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇角色",
      dropdownWidth: 300,
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Description", title: "說明", showHeaderOverflow: true, showOverflow: true, resizable: false },
      ],
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", params), // eslint-disable-line
      },
    };
    const singleWithTreeSelectOptions: SelectBoxOptions = {
      disallowSelectedRowIds: [ 22 ],
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      dropdownWidth: 300,
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        { field: "Number", title: "編號", treeNode: true, showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params) // eslint-disable-line
        }
      },
      treeConfig: { parent: 'ParentId', children: 'Children' },
    };
    const multiselectOptions: SelectBoxOptions = {
      rowId: "Id",
      multiselect: true,
      placeholder: "選擇角色",
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", params), // eslint-disable-line
        insert: (row) => model!.dispatch("role/insert", row), // eslint-disable-line
      },
    };
    const multiselectCanCreateOptions: SelectBoxOptions = {
      rowId: "Id",
      multiselect: true,
      placeholder: "新增角色",
      dropdownWidth: 300,
      textField: "Name",
      valueField: "Id",
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Description", title: "說明", showHeaderOverflow: true, showOverflow: true, resizable: false },
      ],
      addIfNotExists: true,
      promises: {
        find: (value) => model!.dispatch("role/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("role/query", params), // eslint-disable-line
        insert: (row) => model!.dispatch("role/insert", row), // eslint-disable-line
      },
    };
    const multiselectTagsOptions: SelectBoxOptions = {
      rowId: "Id",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true },
      ],
      placeholder: "新增分類",
      multiselect: true,
      showHeader: true,
      // dropDownWidth: 300,
      // dropDownHeight: 435,
      addIfNotExists: true,
      disallowSelectedRowIds: [ 1 ],
      treeConfig: { parent: 'ParentId', children: 'Children' },
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and("ParentId", Operator.IsNull);
          return model!.dispatch("category/query", params); // eslint-disable-line
        },
        insert: (row) => model!.dispatch("category/insert", row), // eslint-disable-line
      },
    };
    const currentValue = ref(null);
    const currentValue2 = ref(null);

    return {
      singleSelectOptions,
      singleWithSearchSelectOptions,
      singleWithTreeSelectOptions,
      multiselectOptions,
      multiselectCanCreateOptions,
      multiselectTagsOptions,
      currentValue,
      currentValue2,
    };
  },
  methods: {
    onDisallowSelect(row: any) {
      console.log('disallowSelect: ', row);
    }
  }
});
</script>
